import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faUserCircle, faDollarSign, faEnvelope } from "@fortawesome/free-solid-svg-icons";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	render() {
	  return (
		<footer>
			<div className="footer_centered">
				<div className="footer_left">
					<div className="footer_left_top">
						<a href="/"><Img src={logo} className="footer_logo" alt="BDSMGayCams" title="Home" /></a>
					</div>
					<div className="footer_left_bottom">
						<h6>Discover the best live fetish gay site online with real bound men! Meet sadism and masochism males sexual pleasures. Get real submissive and dominant cam shows for free. Private sessions avalible!</h6>
					</div>
				</div>
				<div className="footer_right">
					<div>
						<h3>Work With Us <FontAwesomeIcon icon={faDollarSign} /></h3>
						<a href="https://gayboyshow.com/model_register/">Be A Model</a>
						<a href="https://live.truehunks.com/become-affiliate">Be A Affiliate</a>
					</div>
					<div>
						<h3>Discover More <FontAwesomeIcon icon={faUserCircle} /></h3>
						<a href="https://truehunks.com">True Hunks</a>
						<a href="https://liveboysex.com/">Live Boy Sex</a>
					</div>
					<div>
						<h3>Contact <FontAwesomeIcon icon={faEnvelope} /></h3>
						<a href="/contact/">Contact Us</a>
					</div>
				</div>
			</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);
