import React from 'react';
import {Helmet} from 'react-helmet';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import Footer from 'src/components/parts/Footer';

const Error = () => {
    return (
		<div>
			<div className="wrap_sidemenu_also">
				<SideMenuDesktop />
				<div className="not_found">
					<h1>404 Error - Page Not Found</h1>
					<Helmet>
						<title>Page Not Found</title>
						<meta name="robots" content="noindex" />
						<meta name="googlebot" content="noindex" />
					</Helmet>
				</div>
			</div>
			{ window.innerWidth > 768 ? <Footer /> : null }
		</div>
    );
}

export default Error;
 