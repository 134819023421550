//
//	Order user part
//
import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import icon_filters from 'public/static/img/icon_filters.png';
import icon_close from 'public/static/img/icon_close.png';

const mapStateToProps = state  => {
	return {
		users: state.users,
		usersVisible: state.usersVisible,
		usersTotal: state.usersTotal,
		showItems: state.showItems,
		userOrder: state.userOrder,
		top_text: state.top_text,
	};
};

class UserSort extends Component {

	constructor(props) {

		super(props);
		this.state = { isOpen: false }
		this.handleFilters = this.handleFilters.bind(this);
	}


	//	Update filters state
	handleFilters(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}

	//	Order users by specific..
	orderUsers = (event) => {
		this.props.dispatch({type: 'ORDER_USERS', order:event.target.attributes.getNamedItem('data-sort').value});
	};

	
	renderFiltersOpened = () => {
		return (
			<div className="filters_devices">
				<div className="filters_title_close">
					<h2>FILTER GAY WEBCAMS BY:</h2>
					<div className="close_filters" onClick={this.handleFilters}><Img src={icon_close} className="close_icon" alt="Close Icon" /></div>
				</div>
				<div className="sorters">
					<button className="sorter" id={this.props.userOrder == 'ATOZ' ? 'active' : ''} data-sort="ATOZ" onClick={this.orderUsers}>Name A-Z</button>
					<button className="sorter" id={this.props.userOrder == 'ZTOA' ? 'active' : ''} data-sort="ZTOA" onClick={this.orderUsers}>Name Z-A</button>
					<button className="sorter" id={this.props.userOrder == 'POPULAR' ? 'active' : ''} data-sort="POPULAR" onClick={this.orderUsers}>Popular</button>
					<button className="sorter" id={this.props.userOrder == 'OLDEST' ? 'active' : ''} data-sort="OLDEST" onClick={this.orderUsers}>Oldest</button>
					<button className="sorter" id={this.props.userOrder == 'YOUNGEST' ? 'active' : ''} data-sort="YOUNGEST" onClick={this.orderUsers}>Youngest</button>
				</div>
			</div>
		)
	}

	renderFiltersClosed = () => {
		return (
			<div className="open_filters" onClick={this.handleFilters}>
				<Img src={icon_filters} className="header_icon" alt="Filters Icon" />
				<h1>FILTERS</h1>
			</div>
		)
	}

	render() {

		return (
			<div className="navfilters_flex">
				{this.state.isOpen ?  this.renderFiltersOpened() : this.renderFiltersClosed()}
			</div>
		);
	}

}

export default connect(mapStateToProps)(UserSort);