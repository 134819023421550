//
//	Mini CMS in javascript functionality that is used across multiple sites.
//
export var api_url = (document.domain.substr(0,3) === 'dev') ? 'http://' + document.domain : '';


//
//	Get text for the current page. Will return the data and we can use a dispatcher to update values.
//
export function setText(props) {

	//Load Users from Ajax Call
	fetch( api_url + '/web_api/page_content/' )
	.then( results => { return results.json() })
	.then( data => {
		
		//	 Dispatch to event to update 
		//console.log( props );
		props.dispatch({type: 'GET_TEXT', data:data});
		//return data;
	})

}

//
//	Load online users, and dispatch to update data.
//
export function setUsers(props) {

	var url = '';

	// This will load either the initiall list of maybe 20 users, and a full list of maybe 1000 users.
	if( props.usersCompleteLoad ) {
		url = '/web_api/online_users/complete/';
	} else {
		url = '/web_api/online_users/';
	}

	//Load Users from Ajax Call
	fetch( api_url + url )
	  .then( results => { 

			var res = results.json();
			if( res === '' ) {
				return false;
			} else {
				return res;
			}

	}).then( data => {
		if( data.total > 0 ) {
			props.dispatch({type: 'GET_USERS', data:data})
		}
	  })
	  .then(models => {
		
		//	Get the order by from global variable, so we don't have to define on every page.
		var order_by = typeof(localStorage.getItem('userOrder')) !== 'undefined' ? JSON.parse(localStorage.getItem('userOrder') ) : "";
		
		if( order_by !== '' ) {
			props.dispatch({type: 'ORDER_USERS', order: order_by});
		}
	});
	
}

//
//	Get current user.
//
export function getChatrooom(props) {

	//Load Users from Ajax Call
	fetch( api_url + '/web_api/live_room/' )
	.then( results => { return results.json() })
	.then( data => {

		data.iframe = document.location.protocol+'//'+document.domain + data.iframe;
		props.dispatch({type: 'GET_CHATROOM', data:data})

	})

}