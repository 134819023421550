import React from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import icon_menu from 'public/static/img/icon_menu.png';
import icon_search from 'public/static/img/icon_search.png';
import x_icon from 'public/static/img/x_icon.png';

class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}


	renderMobileMenuOpened = () => {

		return (
			<div className="menu_container">
				<div className="overlay" onClick={this.handleMenuButton}></div>
				<div id="menu">
					<div className="menu_top">
						<div className="close_menu_btn" onClick={this.handleMenuButton}><Img src={x_icon} className="close_menu_icon" alt="Close Menu Icon" /></div>
					</div>
					<div className="menu_middle">
						<div className="menu_domsub_selection">
							<NavLink to="/category/master/" className="menu_domsub_btn menu_master_btn" activeClassName="selection_masters" onClick={this.handleMenuButton}>MASTERS</NavLink>
							<NavLink to="/category/slave/" className="menu_domsub_btn menu_slave_btn" activeClassName="selection_slaves" onClick={this.handleMenuButton}>SLAVES</NavLink>
						</div>
					</div>
					<Search />
					<ul className="menu_list">
						<li className="category_heading">BDSM GAY WEBCAMS</li>
						<li><NavLink to="/category/twink/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TWINK</NavLink></li>
						<li><NavLink to="/category/dilf/" activeClassName="active_menulink" onClick={this.handleMenuButton}>DILF</NavLink></li>
						<li><NavLink to="/category/mature/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MATURE</NavLink></li>
						<li><NavLink to="/category/asian/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ASIAN</NavLink></li>
						<li><NavLink to="/category/black/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLACK</NavLink></li>
						<li><NavLink to="/category/latino/" activeClassName="active_menulink" onClick={this.handleMenuButton}>LATINO</NavLink></li>
						<li><NavLink to="/category/white/" activeClassName="active_menulink" onClick={this.handleMenuButton}>WHITE</NavLink></li>
						<li className="category_heading">SPECIALS</li>
						<li><NavLink to="/category/recommended/" activeClassName="active_menulink" onClick={this.handleMenuButton}>RECOMMENDED</NavLink></li>
						<li><NavLink to="/category/hd/" activeClassName="active_menulink" onClick={this.handleMenuButton}>HD STREAMING</NavLink></li>
						<li><NavLink to="/category/new/" activeClassName="active_menulink" onClick={this.handleMenuButton}>AMATEUR SHOWS</NavLink></li>
						<li><NavLink to="/category/smoking/" activeClassName="active_menulink" onClick={this.handleMenuButton}>SMOKING FETISH</NavLink></li>
						<li><NavLink to="/category/foot_fetish/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FOOT FETISH</NavLink></li>
					</ul>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div className="menu_openers">
				<div className="open_search" onClick={this.handleMenuButton}>
					<Img src={icon_search} className="header_icon" alt="Search Icon" />
					<h1>SEARCH</h1>
				</div>
				<div className="open_menu" onClick={this.handleMenuButton}>
					<Img src={icon_menu} className="header_icon" alt="Menu Icon" />
					<h1>MENU</h1>
				</div>
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default MobileMenu;