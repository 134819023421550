//
//	Handle rendering of header, basicly for switches between mobile and desktop
//
import React from 'react';
import logo from 'public/static/img/logo.png';
import MobileMenu from 'src/components/parts/MobileMenu';
import UserSort from 'src/components/parts/UserSort';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
		top_text: state.top_text,
		usersTotal: state.usersTotal,
	};
};

class Header extends React.Component {

	constructor(props) {

		super(props);

	}

	renderMobileHeader = () => {
		return(
			<div className="header_devices">
				<div className="logo"><a href="/"><Img src={logo} className="logo" alt="BDSMGayCams" title="Home" /></a></div>
				<div className="toolbar">
					<UserSort />
					<MobileMenu />
				</div>
			</div>
		)
	}

	renderDesktopHeader = () => {
		return(
			<div className="header_container">
				<header>
					<div className="header_centered">
						<div className="header__pc_top">
							<div className="header__pc_top_left">
								<div className="header__pc_content_left">
									<div className="header__pc_logo"><a href="/"><Img src={logo} className="logo_pc" alt="BDSMGayCams" title="Home" /></a></div>
								</div>
							</div>
							<div className="header__pc_top_right">
								<div className="header__pc_content_right">
									<Search />
								</div>
							</div>
						</div>
					</div>
				</header>
				<nav>
					<div className="nav_centered">
						<div className="navlinks">
							<NavLink to="/" className="navlink" activeClassName="navlink_active" exact>HOME</NavLink>
							<NavLink to="/category/asian/" className="navlink" activeClassName="navlink_active">ASIAN</NavLink>
							<NavLink to="/category/black/" className="navlink" activeClassName="navlink_active">BLACK</NavLink>
							<NavLink to="/category/latino/" className="navlink" activeClassName="navlink_active">LATINO</NavLink>
							<NavLink to="/category/white/" className="navlink" activeClassName="navlink_active">WHITE</NavLink>
							<NavLink to="/category/twink/" className="navlink" activeClassName="navlink_active">TWINK</NavLink>
							<NavLink to="/category/dilf/" className="navlink" activeClassName="navlink_active">DILF</NavLink>
							<NavLink to="/category/big_cock/" className="navlink" activeClassName="navlink_active">BIG COCK</NavLink>
						</div>
						<div className="nav_master_slave">
							<NavLink to="/category/master/" className="domsub_select dom_btn" activeClassName="master_on">MASTERS</NavLink>
							<NavLink to="/category/slave/" className="domsub_select sub_btn" activeClassName="slave_on">SLAVES</NavLink>
						</div>
					</div>
				</nav>
			</div>
		)
	}

	
	render() {

		// At width 768 or greater we will display the desktop header ( default should always be for mobile )
		if(window.innerWidth > 768 ) {

			return(
				this.renderDesktopHeader()
			);

		} else {

			return (
				this.renderMobileHeader()
			);

		}
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Header);