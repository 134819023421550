import React from 'react';
import { NavLink } from 'react-router-dom';
import UserSort from 'src/components/parts/UserSort';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faThumbsUp, faEye, faStar } from "@fortawesome/free-solid-svg-icons";


class SideMenuDesktop extends React.Component {

	constructor(props) {

		super(props);

	}


	render() {

		return (
			<div id="sidemenu__desktop">
				<div className="sidemenu">
					<div className="sidemenu_categories">
						<ul id="side_list">
							<li className="side_list_title">POPULAR</li>
							<li><NavLink to="/category/recommended/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faThumbsUp} /> Recommended</NavLink></li>
							<li><NavLink to="/category/hd/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faEye} /> HD Streaming</NavLink></li>
							<li><NavLink to="/category/new/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faStar} /> Amateur Webcams</NavLink></li>
							<li className="side_list_title">AGE</li>
							<li><NavLink to="/category/twink/" activeClassName="active_sidepage">Twink Shows</NavLink></li>
							<li><NavLink to="/category/dilf/" activeClassName="active_sidepage">DILF Shows</NavLink></li>
							<li><NavLink to="/category/mature/" activeClassName="active_sidepage">Mature Shows</NavLink></li>
							<li className="side_list_title">ETHNICITY</li>
							<li><NavLink to="/category/asian/" activeClassName="active_sidepage">Asian Gays</NavLink></li>
							<li><NavLink to="/category/black/" activeClassName="active_sidepage">Black Gays</NavLink></li>
							<li><NavLink to="/category/latino/" activeClassName="active_sidepage">Latino Gays</NavLink></li>
							<li><NavLink to="/category/white/" activeClassName="active_sidepage">White Gays</NavLink></li>
							<li className="side_list_title">SPECIALS</li>
							<li><NavLink to="/category/smoking/" activeClassName="active_sidepage">Smoking Fetish</NavLink></li>
							<li><NavLink to="/category/foot_fetish/" activeClassName="active_sidepage">Foot Fetish</NavLink></li>
							<li><NavLink to="/category/big_cock/" activeClassName="active_sidepage">Big Cock</NavLink></li>
						</ul>
						<UserSort />
					</div>
				</div>
			</div>
		);
	}
}

export default SideMenuDesktop;