import React, { Component } from 'react';
import { connect } from "react-redux";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  text_content: state.text_content
	};
};

//	Content text class
class TextContent extends Component {

	constructor(props) {

		super(props);
		
	}

	render() {
	  return (
		<div id="content">
			<div className="text_content" dangerouslySetInnerHTML={{__html: this.props.text_content}}></div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(TextContent);




