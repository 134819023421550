import React from 'react';
import SideMenuDesktop from 'src/components/parts/SideMenuDesktop';
import Footer from 'src/components/parts/Footer';

const Contact = () => {
    return (
		<div>
			<div className="wrap_sidemenu_also">
				<SideMenuDesktop />
				<div className="contact">
					<h1>Contact</h1>
					<p>875 S Broadway #56 Los Angeles, CA 90013</p>
					<p>Phone: 586-679-3496 </p>
					<p>Email: contact@bdsmgaycams.com</p>
					<p>Twitter: @bdsmgaycams</p>
				</div>
			</div>
			{ window.innerWidth > 768 ? <Footer /> : null }
		</div>
    );
}

export default Contact;